import React from 'react'

// Styles
import {
  blackWrapper,
  imgContainer,
  imgStyle,
  imgWrapper,
} from './styles.module.scss'

const Schedule: React.FC = () => {
  return (
    <>
      <div className={`block-wrapper ${blackWrapper}`}>
        <div className="container relative">
          <div className={imgContainer}>
            <div className={imgWrapper}>
              <img
                src="https://ficcofestival.com/img/schedule/Viernes.png"
                className={imgStyle}
              />
            </div>
            <div className={imgWrapper}>
              <img
                src="https://ficcofestival.com/img/schedule/Sabado.png"
                className={imgStyle}
              />
            </div>
            <div className={imgWrapper}>
              <img
                src="https://ficcofestival.com/img/schedule/Domingo.png"
                className={imgStyle}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Schedule
