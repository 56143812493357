import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import Header from '../components/atoms/Header'
import Footer from '../components/atoms/Footer'
import Schedule from '../components/organisms/Schedule'

const SchedulePage = () => (
  <>
    <Helmet>
      <title>FICCO 2022</title>
      <meta
        name="description"
        content="Conoce la programación del Festival Independiente de Comic Colombiano 2022. Un evento para artistas y autores de cómic nacional para reunirse, exhibir y aprender del noveno arte..."
      />
      <meta
        name="keywords"
        content="programacion, festival, webcomic, comic, evento, fantasia, magia, realismo magico, colombia, biblioteca virgilio barco, colombiano, homepage"
      />
      <meta name="author" content="Daniel Penagos" />
      <meta name="copyright" content="Daniel Penagos" />
    </Helmet>
    <Header />
    <Schedule />
    <Footer />
  </>
)

export default SchedulePage
